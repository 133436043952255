export default function LocationIcon({ fillColor }) {
  return (
    <svg
      height="16"
      stroke="transparent"
      version="1.1"
      viewBox="0 0 40 40"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
    >
      <path
        clip-rule="evenodd"
        d="M32 18.1053C32 22.2563 29.1404 26.2127 25.3716 29.4979C23.5742 31.0647 21.7579 32.3125 20.3827 33.1705C20.2505 33.2529 20.1228 33.3316 20 33.4063C19.8772 33.3316 19.7495 33.2529 19.6173 33.1705C18.2421 32.3125 16.4257 31.0647 14.6284 29.4979C10.8596 26.2127 8 22.2563 8 18.1053C8 11.3949 13.3973 6 20 6C26.6027 6 32 11.3949 32 18.1053ZM36 18.1053C36 30 20 38 20 38C20 38 4 30 4 18.1053C4 9.21057 11.1634 2 20 2C28.8366 2 36 9.21057 36 18.1053ZM22 18C22 19.1046 21.1046 20 20 20C18.8954 20 18 19.1046 18 18C18 16.8954 18.8954 16 20 16C21.1046 16 22 16.8954 22 18ZM26 18C26 21.3137 23.3137 24 20 24C16.6863 24 14 21.3137 14 18C14 14.6863 16.6863 12 20 12C23.3137 12 26 14.6863 26 18Z"
        fill="#161e2d"
        fill-rule="evenodd"
      ></path>
    </svg>
  );
}
