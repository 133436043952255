import React from "react";
import { useParams } from "react-router-dom";
import { Layout } from "antd";
import NavigationBar from "./mainNavigationBar";
import AppFooter from "./Footer";

const { Header, Content, Footer } = Layout;

const jobPostings = [
  {
    id: "computational-biologist",
    jobTitle: "Computational Biologist",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4083817567/",
    about:
      "Flahy is a biotechnology company that develops artificial intelligence driven detection & diagnostics products for Precision Medicine and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a computational platform that leverages sophisticated statistical & computational capabilities to analyze -omics data across individuals and populations for prognosis, design of precise treatments, and target discovery. FlahyRecovery™, Flahy's first precision diagnostic product built on top of FlahyBase will be released soon.",
    positionDescription: "Work on precision diagnostic product line...",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve analysing clinical and research literature, existing products, and computing over -omics datasets to identify patterns that are of clinical relevance for tumour sub-typing, and subsequent treatment/therapeutic guidance.",
      "Maintain and continue to develop in-house developed -omics data analysis algorithms and software tools/pipelines.",
      "Exploration and deployment of external algorithms, software, and tools for omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in biological sciences, computational biology, mathematics, statistics, computer science or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Strong research & development experience in either an academic or an industrial setting.",
      "Credible publications are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "CBFHY",
  },
  {
    id: "data-scientist",
    jobTitle: "Data Scientist: ML Engineer",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4102189254/",
    about:
      "Flahy is a biotechnology company that develops artificial intelligence driven detection & diagnostics products for Precision Medicine and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a computational platform that leverages sophisticated statistical & computational capabilities to analyze -omics data across individuals and populations for prognosis, design of precise treatments, and target discovery. FlahyRecovery™, Flahy's first precision diagnostic product built on top of FlahyBase will be released soon.",
    positionDescription:
      "Flahy is seeking a Data Scientist / Machine Learning engineer with a strong background in theocratical mathematics & statistics to collaborate with the team on development of its computational platform and proprietary scientific computing libraries.",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve designing mathematical/statistical models for analysis of large graphical, high-dimensional, and multi-modal data.",
      "Design and train machine learning models for featuring learning, segmentation, and classification from medical imaging data.",
      "Maintain and continue to develop in-house developed -omics data analysis software.",
      "Exploration and deployment of external algorithms, software, and tools for -omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in mathematics, statistics, physics, computer science, biomedical engineering, or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Research experience in mathematics, theoretical physics, or machine/statistical learning.",
      "Credible publications or preprints are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "MLFHY",
  },
];

const JobPosting = () => {
  const { jobId } = useParams();
  const job = jobPostings.find((job) => job.id === jobId);

  if (!job) return <div>Job not found</div>;

  return (
    <>
      <Layout className="flex flex-col font-futura min-h-screen bg-white">
        <Header className="flex w-full p-0 bg-white">
          <NavigationBar />
        </Header>
        <Content className="bg-white  px-10 py-10 flex flex-col justify-center items-center">
          <div className="flex flex-col bg-[#F1F1EF] shadow-md shadow-[#dbdbd9] w-screen sm:w-screen md:w-10/12 lg:w-10/12 xl:w-10/12 2xl:w-10/12">
            <p className="font-proxima-nova text-3xl font-thin ml-6 mt-6">
              {job.jobTitle}
            </p>
            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              About
            </p>
            <p className="font-proxima-nova text-base font-thin mt-2 mx-6">
              {job.about}
            </p>

            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              Position Responsibilities
            </p>
            <ul className="list-disc ml-10">
              {job.positionResponsibilities.map((item, index) => (
                <li
                  key={index}
                  className=" font-proxima-nova text-base font-thin mt-2"
                >
                  {item}
                </li>
              ))}
            </ul>

            <p className="font-proxima-nova text-2xl font-thin mx-6 mt-6">
              Minimum Requirements
            </p>

            <ul className="list-disc ml-10">
              {job.minimumRequirements.map((item, index) => (
                <li
                  key={index}
                  className="font-proxima-nova text-base font-thin mt-2"
                >
                  {item}
                </li>
              ))}
            </ul>

            <p className="font-proxima-nova text-base font-thin mt-10 mb-6 mx-6">
              If interested please apply{" "}
              <a
                className=" text-blue-500"
                href={job.linkedinpostinglink}
                target="_blank"
                rel="noopener noreferrer"
              >
                here
              </a>{" "}
              or email{" "}
              <a className="text-blue-500" href="mailto:contact@flahybase.com">
                contact@flahybase.com
              </a>{" "}
              with subject "{job.emailSubject}".
            </p>
          </div>
        </Content>
        <Footer className="p-0 bg-white">
          <AppFooter />
        </Footer>
      </Layout>
    </>
  );
};

export default JobPosting;
