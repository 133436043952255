import React, { useState } from "react";
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
  Link,
} from "@nextui-org/react";
import { CompanyLogo } from "./companyLogo";
import { useLocation } from "react-router-dom";

const NavigationBar = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const menuItems = [
    { label: "Home", route: "/home" },
    { label: "Base", route: "/base" },
    { label: "Diagnostics", route: "https://flahyrecovery.com" },
    { label: "Team", route: "/about" },
    { label: "Contact Us", route: "/contactus" },
  ];

  return (
    <Navbar
      shouldHideOnScroll
      onMenuOpenChange={setIsMenuOpen}
      isBordered={true}
      maxWidth="full"
      className=" bg-white font-proxima-nova font-thin"
    >
      <NavbarContent className="sm:hidden" justify="start">
        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />
        <NavbarBrand>
          <Link href="/home" className="text-black hover:text-black gap-4">
            <CompanyLogo width="40" height="40" />
            <p className="tracking-widest custom-font text-lg text-black uppercase">
              Flahy
            </p>
          </Link>
        </NavbarBrand>
      </NavbarContent>
      {/* <NavbarContent className="sm:hidden" justify="left">
        <NavbarBrand>
          <Link href="/home" className="text-black hover:text-black gap-4">
            <CompanyLogo width="40" height="40" />
            <p className="tracking-widest custom-font text-lg text-black uppercase">
              Flahybase
            </p>
          </Link>
        </NavbarBrand>
      </NavbarContent> */}
      <NavbarContent className="hidden sm:flex gap-10" justify="end">
        <NavbarBrand className="gap-4">
          <Link href="/home" className="text-black hover:text-black gap-4">
            <CompanyLogo width="40" height="40" />
            <p className="tracking-widest custom-font text-lg text-black uppercase">
              Flahy
            </p>
          </Link>
        </NavbarBrand>
        <NavbarItem>
          <Link
            className={`text-black hover:text-black ${location.pathname === "/home" || location.pathname === "/"
              ? " border-black border-b-1 pb-1 pt-1"
              : "border-white border-b-1 pb-1 pt-1"
              }`}
            href="/home"
          >
            Home
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            className={`text-black hover:text-black ${location.pathname === "/base"
              ? " border-black border-b-1 pb-1 pt-1"
              : "border-white border-b-1 pb-1 pt-1"
              }`}
            href="/base"
          >
            Base
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Link
            className={`text-black hover:text-black `}
            href="https://flahyrecovery.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Diagnostics
          </Link>
        </NavbarItem>

        <NavbarItem>
          <Link
            className={`text-black hover:text-black ${location.pathname === "/about"
              ? " border-black border-b-1 pb-1 pt-1"
              : "border-white border-b-1 pb-1 pt-1"
              }`}
            href="/about"
          >
            Team
          </Link>
        </NavbarItem>
        {/* <NavbarItem>
          <Link
            className={`text-black hover:text-black ${
              location.pathname === "/clinics"
                ? " border-black border-b-1 pb-1 pt-1"
                : "border-white border-b-1 pb-1 pt-1"
            }`}
            href="/clinics"
          >
            For Clinics
          </Link>
        </NavbarItem> */}
        <NavbarItem>
          <Link
            href="/contactus"
            className="text-white hover:text-white bg-black p-2 rounded-md"
            aria-current="page"
          >
            Contact Us
          </Link>
        </NavbarItem>
      </NavbarContent>
      {/* <NavbarContent className="gap-4 invisible" justify="end">
        <NavbarItem className=" lg:flex">
          <Link href="#" className="text-black">
            Login
          </Link>
        </NavbarItem>
        <NavbarItem>
          <Button as={Link} color="default" href="#" variant="flat">
            <span className="text-black">Sign Up</span>
          </Button>
        </NavbarItem>
      </NavbarContent> */}
      <NavbarMenu>
        {menuItems.map((item, index) => (
          <NavbarMenu className="bg-white">
            {menuItems.map((menuItem, index) => (
              <NavbarMenuItem key={index}>
                <Link
                  href={menuItem.route}
                  target={
                    menuItem.label === "Diagnostics" ? "_blank" : undefined
                  }
                  rel={
                    menuItem.label === "Diagnostics"
                      ? "noopener noreferrer"
                      : undefined
                  }
                  className={`text-black hover:text-black ${location.pathname === menuItem.route
                    ? "border-black border-b-1 pb-1 pt-1"
                    : "border-white border-b-1 pb-1 pt-1"
                    }`}
                >
                  {menuItem.label}
                </Link>
              </NavbarMenuItem>
            ))}
          </NavbarMenu>
        ))}
      </NavbarMenu>
    </Navbar>
  );
};

export default NavigationBar;
