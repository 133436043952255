import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { NextUIProvider } from "@nextui-org/react";
import { Home } from "./Home";
import { About } from "./About";
import { Base } from "./Base";
import { Clinics } from "./Clinics";
import { ContactUs } from "./ContactUs";
import JobPosting from "./Components/JobPosting";
import ReactGA from "react-ga";

import "./App.css";

ReactGA.initialize("G-6W2LE4R437");

const TrackPageView = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.set({ page: location.pathname }); // Set the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  }, [location]);

  return null; // This component does not render anything
};

const App = () => {
  return (
    <NextUIProvider>
      <BrowserRouter>
        <TrackPageView />
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/base" element={<Base />} />
          <Route path="/clinics" element={<Clinics />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/job/:jobId" element={<JobPosting />} />
          <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </NextUIProvider>
  );
};

export default App;
