import React from "react";
import { Layout } from "antd";
import NavigationBar from "./Components/mainNavigationBar";
import AppFooter from "./Components/Footer";
import Card from "./Components/Card";
import founderPhoto from "./team_pics/jagjit2.jpg";
import christopher_mason from "./team_pics/Mason.jpg";
import raul from "./team_pics/raul.jpg";
import sunny from "./team_pics/sunny.jpg";
import photo3 from "./team_pics/andrew.jpg";
import anjali_photo from "./team_pics/anjali.png";
import kaja from "./team_pics/kaja_photo.jpeg";
import arun_goyal from "./team_pics/arun_goyal.jpeg";
import vaibhav_jaiswal from "./team_pics/vaibhav_photo.png";
import jayasuriya from "./team_pics/jayasuriya.png";
import sonia from "./team_pics/sonia.jpg";
import LocationIcon from "./Components/LocationIcon";
import DateIcon from "./Components/DateIcon";
import { Link } from "react-router-dom";
const { Header, Content, Footer } = Layout;

const jobPostings = [
  {
    id: "computational-biologist",
    jobTitle: "Computational Biologist",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4102189254/",
    about:
      "Flahy is a biotechnology company that develops artificial intelligence driven detection & diagnostics products for Precision Medicine and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a computational platform that leverages sophisticated statistical & computational capabilities to analyze -omics data across individuals and populations for prognosis, design of precise treatments, and target discovery. FlahyRecovery™, Flahy’s first precision diagnostic product built on top of FlahyBase will be released soon.",
    positionDescription: "Work on precision diagnostic product line...",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve analysing clinical and research literature, existing products, and computing over -omics datasets to identify patterns that are of clinical relevance for tumour sub-typing, and subsequent treatment/therapeutic guidance.",
      "Maintain and continue to develop in-house developed -omics data analysis algorithms and software tools/pipelines.",
      "Exploration and deployment of external algorithms, software, and tools for omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in biological sciences, computational biology, mathematics, statistics, computer science or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Strong research & development experience in either an academic or an industrial setting.",
      "Credible publications are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "CBFHY",
  },
  {
    id: "data-scientist",
    jobTitle: "Data Scientist: ML Engineer",
    linkedinpostinglink: "https://www.linkedin.com/jobs/view/4083817567/",
    about:
      "Flahy is a biotechnology company that develops artificial intelligence driven detection & diagnostics products for Precision Medicine and collaborates with institutions for the development of targeted therapies. Flahy has developed FlahyBase, a computational platform that leverages sophisticated statistical & computational capabilities to analyze -omics data across individuals and populations for prognosis, design of precise treatments, and target discovery. FlahyRecovery™, Flahy’s first precision diagnostic product built on top of FlahyBase will be released soon.",
    positionDescription:
      "Flahy is seeking a Data Scientist / Machine Learning engineer with a strong background in theocratical mathematics & statistics to collaborate with the team on development of its computational platform and proprietary scientific computing libraries.",
    positionResponsibilities: [
      "Conducting product focused Research and Development. This would involve designing mathematical/statistical models for analysis of large graphical, high-dimensional, and multi-modal data.",
      "Design and train machine learning models for featuring learning, segmentation, and classification from medical imaging data.",
      "Maintain and continue to develop in-house developed -omics data analysis software.",
      "Exploration and deployment of external algorithms, software, and tools for -omics data analysis.",
      "Work with team members at Flahy to develop the computational platform and a proprietary database of sequenced and imaged tissue data.",
      "Multi-task in a fast-paced environment.",
    ],
    minimumRequirements: [
      "An MSc. or a Ph.D. in mathematics, statistics, physics, computer science, biomedical engineering, or related quantitative field. Or a B.Tech/B.Eng/B.Sc from a credible institution.",
      "Research experience in mathematics, theoretical physics, or machine/statistical learning.",
      "Credible publications or preprints are a plus.",
    ],
    contactEmail: "contact@flahybase.com",
    emailSubject: "MLFHY",
  },
];

export const About = () => (
  <>
    <Layout className="flex flex-col font-futura min-h-screen bg-white">
      <Header className="flex w-full p-0 bg-white">
        <NavigationBar />
      </Header>
      <Content className="bg-white">
        <div className="bg-white w-full flex flex-col flex-grow justify-center items-center">
          <h1 className="font-proxima-nova font-thin text-5xl pt-20 text-black text-center">
            Meet Our Team
          </h1>
          <p className=" font-proxima-nova text-2xl font-thin mt-10 mx-20 text-center">
            Designing And Building The Ecosystem
          </p>
        </div>
        <div className="bg-white w-full flex-grow min-h-full mt-10 mb-20 grid md:grid-cols-2 lg:grid-cols-4 grid-cols-1 justify-start items-start">
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={photo3}
              name="Andrew Blumberg"
              position="Advisor @Flahy"
              text="Herbert and Florence Irving Professor of Cancer Data Research at Columbia University Irving Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={anjali_photo}
              name="Anjali Barnwal"
              position="Visiting Scientist @Flahy"
              text="Post-Doctoral Associate Duke University Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={arun_goyal}
              name="Arun Goyal"
              position="Independent Director @Flahy India"
              text="Ex. Vice Chairman of Trident Group"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={christopher_mason}
              name="Christopher Mason"
              position="Advisor @Flahy"
              text="Professor of Physiology and Biophysics at Weill Cornell Medicine"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={founderPhoto}
              name="Jagjit Singh"
              position="CEO & Biostatistician @Flahy"
              text="MSc Biostatistics Columbia University"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={kaja}
              name="Kaja Wasik"
              position="Advisor @Flahy"
              text="Co-Founder Variant Bio and Gencove"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={raul}
              name="Raúl Rabadán"
              position="Advisor @Flahy"
              text="Professor of Systems Biology at Columbia University Irving Medical Center"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={vaibhav_jaiswal}
              name="Vaibhav Jaiswal"
              position="Bioinformatics Engineer @Flahy"
              text="MSc. Biological Sciences Indian Institute Of Technology"
            />
          </div>
          <div className="bg-white flex items-center justify-center">
            <Card
              imageSrc={sonia}
              name="Sonia"
              position="Biologist @Flahy"
              text="MSc. Thapar Institute of Technology"
            />
          </div>
        </div>
        <div className="bg-white w-full flex flex-col flex-grow justify-center items-center border-[#F1F1EF] border-t-1">
          <p className=" font-proxima-nova text-3xl font-thin mt-10 mx-20 text-center">
            Current Openings
          </p>
        </div>
        <div className=" w-full flex-grow min-h-full mt-10 mb-20 grid md:grid-cols-3 lg:grid-cols-3 grid-cols-1 justify-start items-start">
          {jobPostings.map((job) => (
            <div
              key={job.id}
              className="flex flex-col w-10/12 bg-[#F1F1EF] shadow-md shadow-[#dbdbd9] justify-start items-start mx-auto m-5"
            >
              <p className="font-proxima-nova text-lg font-thin mt-4 ml-4 text-start">
                {job.jobTitle}
              </p>
              <div className="font-proxima-nova flex flex-row text-center text-lg font-thin ml-4 items-center ">
                <LocationIcon />
                <p className="text-sm pl-1 bg-[#F1F1EF]">Chandigarh, India</p>
              </div>
              <p className="font-proxima-nova mx-4 font-thin flex mt-6 mb-4 items-center justify-center text-sm">
                {job.about.slice(0, 100)}...
              </p>
              <div className="font-proxima-nova font-thin items-center justify-center self-center flex flex-col mb-6">
                <Link className="text-blue-500 underline" to={`/job/${job.id}`}>
                  View More
                </Link>
              </div>
            </div>
          ))}
          {/* <div className="flex flex-col w-10/12 bg-[#F1F1EF] shadow-md shadow-[#dbdbd9] justify-start items-start mx-auto m-5">
            <p className="font-proxima-nova text-lg font-thin mt-4 ml-4 text-start">
              Computational Biologist
            </p>
            <div className="font-proxima-nova flex flex-row text-center text-lg font-thin ml-4 items-center ">
              <LocationIcon />
              <p className="text-sm pl-1 bg-[#F1F1EF]">Remote</p>
            </div>
            <p className="font-proxima-nova mx-4 font-thin flex mt-6 mb-4 items-center justify-center text-sm">
              Flahy is seeking a Computational Biologist/Research Scientist with
              strong programming skills and research experience to collaborate
              with the team on its precision diagnostic product line.
            </p>
            <div className="font-proxima-nova font-thin items-center justify-center self-center flex flex-col mb-6">
              <Link to="/home">View More</Link>
            </div>
          </div>
          <div className="flex flex-col w-10/12 bg-[#F1F1EF] shadow-md shadow-[#dbdbd9] justify-start items-start mx-auto m-5">
            <p className="font-proxima-nova text-lg font-thin mt-4 ml-4 text-start">
              Data Scientist: ML Engineeer
            </p>
            <div className="font-proxima-nova flex flex-row text-center text-lg font-thin ml-4 items-center ">
              <LocationIcon />
              <p className="text-sm pl-1 bg-[#F1F1EF]">Remote</p>
            </div>
            <p className="font-proxima-nova mx-4 font-thin flex mt-6 mb-4 items-center justify-center text-sm">
              Flahy is seeking a Data Scientist / Machine Learning engineer with
              a strong background in theocratical mathematics & statistics to
              collaborate with the team on development of its computational
              platform and proprietary scientific computing libraries.
            </p>
            <div className="font-proxima-nova font-thin items-center justify-center self-center flex flex-col mb-6">
              <Link to="/home">View More</Link>
            </div>
          </div> */}
        </div>
      </Content>
      <Footer className="p-0 bg-white">
        <AppFooter />
      </Footer>
    </Layout>
  </>
);
