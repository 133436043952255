import React from "react";
import { useSyncExternalStore } from "use-sync-external-store/shim";

export function useMediaQuery(query) {
  const subscribe = React.useCallback(
    (callback) => {
      const matchMedia = window.matchMedia(query);

      matchMedia.addEventListener("change", callback);
      return () => {
        matchMedia.removeEventListener("change", callback);
      };
    },
    [query]
  );

  const getSnapshot = () => {
    return window.matchMedia(query).matches;
  };
  return useSyncExternalStore(subscribe, getSnapshot);
}

export function useDeviceType() {
  return [
    useMediaQuery("only screen and (max-width : 768px)"), //isSmallDevice
    useMediaQuery(
      "only screen and (min-width : 769px) and (max-width : 992px)" //isMediumDevice
    ),
    useMediaQuery(
      "only screen and (min-width : 993px) and (max-width : 1200px)" //isLargeDevice
    ),
    useMediaQuery("only screen and (min-width : 1201px)"), //isExtraLargeDevice
  ];
}
