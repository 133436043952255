import React from "react";

function Card({ imageSrc, name, position, text }) {
  return (
    <div className="w-72 h-auto  bg-[#F1F1EF] p-5 mx-auto text-center flex flex-col m-5">
      <div className="self-center w-60 h-60 rounded-full overflow-hidden ">
        <img
          // className="w-full h-auto object-cover object-center grayscale transition-transform duration-500 ease-in-out hover:scale-125"
          className="w-full h-auto object-cover object-center grayscale"
          src={imageSrc}
          alt={name}
        />
      </div>
      <div className="mt-4 text-2xl font-proxima-nova font-thin text-left">
        {name}
      </div>
      <div className="mt-2 text-black font-proxima-nova font-thin text-left text-base italic">
        {position}
      </div>
      <div className="mt-2 text-black font-proxima-nova font-thin text-left text-base italic">
        {text}
      </div>
    </div>
  );
}

export default Card;
