import React, { memo, useEffect } from 'react';
import { ReactP5Wrapper } from 'react-p5-wrapper';
import p5 from 'p5';
import { useDeviceType } from '../hooks/useDeviceType';

const HalvorsenAttractorComponent = memo(() => {
    const [isSmallDevice, isMediumDevice, isLargeDevice] = useDeviceType();
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.p5 = p5;
            // Import p5.easycam dynamically after p5 is set
            import('p5.easycam')
                .then(() => {
                    console.log('EasyCam loaded successfully');
                })
                .catch(err => console.error('Error loading EasyCam:', err));
        }
    }, []);

    const sketch = (p) => {
        let easycam;
        let particles = [];
        let points = [];
        let attractor;
        let NUM_POINTS = 3000;
        let numMax = 50;
        let t = 0;
        let h = 0.009;
        let currentParticle = 0;
        let font;
        let names = ["PUSL1", "PEX10", "CPTP", "SKI", "MEGF6", "TAS1R3", "ERRFI1", "PEX14", "ENO1", "TP73", "HES5", "PARK7", "CLIC4", "GPR3", "SDC3", "SYTL1", "EYA3", "FABP3", "EPHA2", "PAX7", "EXTL1", "CASZ1", "AGMAT", "FHL3", "RLF", "GNL2", "GJA4", "ERI3", "CZIB", "PCSK9", "TTC4", "KLF17", "PLPP3", "RSPO1", "SNIP1", "CPT2", "FOXJ3", "TUT4"];
        let centerText;
        let circularName = "AGCT";
        const canvasWidth = p.windowWidth;
        p.preload = () => {
            font = p.loadFont('https://cdnjs.cloudflare.com/ajax/libs/topcoat/0.8.0/font/SourceSansPro-Regular.otf',
                () => console.log('Font loaded successfully'),
                (err) => console.error('Font loading error:', err)
            );
        };

        // Add this before p.setup
        if (typeof window !== 'undefined') {
            window.p5 = p5;
        }

        let parDef = {
            Attractor: 'Halvorsen',
            Speed: 1.0,
            Particles: true,
            Preset: function () {
                p.removeElements();
                this.Speed = 1.0;
                this.Particles = true;
                attractor.a = 1.89;
                attractor.x = -1.48;
                attractor.y = -1.51;
                attractor.z = 2.04;
                for (let i = points.length - 1; i >= 0; i -= 1) {
                    points.splice(i, 1);
                }
                initSketch();
            },
            Randomize: randomCurve,
        };

        p.setup = async () => {
            const existingCanvas = document.querySelector('canvas');
            if (existingCanvas) {
                existingCanvas.remove();
            }

            attractor = new HalvorsenAttractor(p);
            // p.pixelDensity(1);
            p.pixelDensity(isSmallDevice ? 1 : 2);

            let canvas = p.createCanvas(canvasWidth, p.windowHeight, p.WEBGL);
            p.setAttributes('antialias', true);

            // Create EasyCam after ensuring it's loaded
            if (typeof p.createEasyCam === 'function') {
                easycam = p.createEasyCam({ distance: isSmallDevice ? 30 : isMediumDevice ? 23 : isLargeDevice ? 17 : 14 });
            }

            // Initialize the sketch
            initSketch();
        };

        p.draw = () => {
            // Make sure we have a valid WebGL context
            if (!p._renderer.isP3D) {
                console.error('Not in WEBGL mode');
                return;
            }

            // projection
            p.perspective(60 * p.PI / 180, p.width / p.height, 1, 5000);

            // BG
            p.background(5, 8, 40);
            p.translate(isSmallDevice ? 0 : -1.5, 0, -5);
            p.rotateX(-0.5);
            p.rotateY(p.PI / 2 + 0.7);

            p.beginShape(p.LINE_STRIP);
            for (let v of points) {
                p.stroke(255, 255, 255);
                p.strokeWeight(0.05);
                p.vertex(v.x, v.y, v.z);
            }
            p.endShape();

            if (centerText) {
                centerText.update();
                centerText.display();
            }

            if (parDef.Particles == true) {
                //updating and displaying the particles
                for (let i = particles.length - 1; i >= 0; i -= 1) {
                    let p = particles[i];
                    p.update();
                    p.display();
                }
            }
        };

        function componentFX(t, x, y, z) {
            return 0.3 * parDef.Speed * (-attractor.a * x - 4 * y - 4 * z - y * y);
        }

        function componentFY(t, x, y, z) {
            return 0.3 * parDef.Speed * (-attractor.a * y - 4 * z - 4 * x - z * z);
        }

        function componentFZ(t, x, y, z) {
            return 0.3 * parDef.Speed * (-attractor.a * z - 4 * x - 4 * y - x * x);
        }

        function rungeKutta(time, x, y, z, h) {
            let k1 = componentFX(time, x, y, z);
            let j1 = componentFY(time, x, y, z);
            let i1 = componentFZ(time, x, y, z);

            let k2 = componentFX(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k1,
                y + (1 / 2) * h * j1,
                z + (1 / 2) * h * i1
            );
            let j2 = componentFY(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k1,
                y + (1 / 2) * h * j1,
                z + (1 / 2) * h * i1
            );
            let i2 = componentFZ(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k1,
                y + (1 / 2) * h * j1,
                z + (1 / 2) * h * i1
            );
            let k3 = componentFX(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k2,
                y + (1 / 2) * h * j2,
                z + (1 / 2) * h * i2
            );
            let j3 = componentFY(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k2,
                y + (1 / 2) * h * j2,
                z + (1 / 2) * h * i2
            );
            let i3 = componentFZ(
                time + (1 / 2) * h,
                x + (1 / 2) * h * k2,
                y + (1 / 2) * h * j2,
                z + (1 / 2) * h * i2
            );
            let k4 = componentFX(time + h, x + h * k3, y + h * j3, z + h * i3);
            let j4 = componentFY(time + h, x + h * k3, y + h * j3, z + h * i3);
            let i4 = componentFZ(time + h, x + h * k3, y + h * j3, z + h * i3);
            x = x + (h / 6) * (k1 + 2 * k2 + 2 * k3 + k4);
            y = y + (h / 6) * (j1 + 2 * j2 + 2 * j3 + j4);
            z = z + (h / 6) * (i1 + 2 * i2 + 2 * i3 + i4);
            return {
                u: x,
                v: y,
                w: z
            };
        }

        class Particle {
            constructor(_x, _y, _z, _t, _h, text) {
                this.x = _x;
                this.y = _y;
                this.z = _z;
                this.time = _t;
                this.h = _h;
                this.text = text;

                // Add offset properties (reduced offset values)
                this.offset = {
                    x: p.random(-0.2, 0.2),
                    y: p.random(-0.2, 0.2),
                    z: p.random(-0.2, 0.2)
                };

                // Color properties with higher opacity
                this.r = p.random(255);
                this.g = p.random(200, 255);
                this.b = p.random(200, 255);
                // this.opacity = 255; // Full opacity

                // Initialize smooth position
                this.smoothX = this.x;
                this.smoothY = this.y;
                this.smoothZ = this.z;
            }

            update() {
                let tmp = rungeKutta(this.time, this.x, this.y, this.z, this.h);

                this.x = tmp.u;
                this.y = tmp.v;
                this.z = tmp.w;

                // Smoother movement
                this.smoothX = p.lerp(this.smoothX, this.x + this.offset.x, 0.05);
                this.smoothY = p.lerp(this.smoothY, this.y + this.offset.y, 0.05);
                this.smoothZ = p.lerp(this.smoothZ, this.z + this.offset.z, 0.05);

                this.time += this.h;
            }

            display() {
                p.push();
                p.translate(this.smoothX, this.smoothY, this.smoothZ);

                // Ensure text faces camera
                if (easycam) {
                    let state = easycam.getState();
                    p.rotateX(360);
                    p.rotateY(150);
                    p.rotateZ(15);
                }

                p.noStroke();
                p.fill(255, 255, 255, this.opacity);

                // Increase text size and ensure font is used
                if (font) {
                    p.textFont(font);
                    p.textSize(0.3); // Reduced text size
                    p.textAlign(p.CENTER, p.CENTER);
                    p.text(this.text, 0, 0);
                }

                p.pop();
            }
        }

        function initSketch() {
            // Initialize point with attractor's initial values
            let point = {
                x: attractor.x,
                y: attractor.y,
                z: attractor.z
            };

            // Clear existing points
            points = [];

            // Generate points for the curve
            for (let j = 0; j < NUM_POINTS; j++) {
                let newPoint = attractor.generatePoint(point.x, point.y, point.z);

                if (isNaN(newPoint.x) || isNaN(newPoint.y) || isNaN(newPoint.z)) {
                    console.log('Failed, retry');
                    randomCurve();
                    return;
                }

                points.push(new p5.Vector(
                    attractor.scale * newPoint.x,
                    attractor.scale * newPoint.y,
                    attractor.scale * newPoint.z
                ));

                // Update point for next iteration
                point = newPoint;
            }

            // Create particles
            particles = [];
            for (let i = 0; i < numMax; i++) {
                let startPoint = points[Math.floor(p.random(0, points.length))];
                particles[i] = new Particle(
                    startPoint.x,
                    startPoint.y,
                    startPoint.z,
                    t,
                    h,
                    names[Math.floor(p.random(0, names.length))]
                );
            }

            centerText = new CenterText(p, easycam, font, "TCGATGGGAATCTGTTGAGGCTTGT");
        }

        function randomCurve() {
            attractor.randomize();
            initSketch();
        }
    };

    class HalvorsenAttractor {
        constructor(p5Instance) {
            this.p = p5Instance;  // Store p5 instance
            this.speed = 0.5;
            this.a = 1.89;
            this.x = -1.48;
            this.y = -1.51;
            this.z = 2.04;
            this.h = 0.03;
            this.scale = isSmallDevice ? 0.5 : isMediumDevice ? 0.4 : isLargeDevice ? 0.5 : 0.58;
        }

        generatePoint(x, y, z) {
            var nx = this.speed * (-this.a * x - 4 * y - 4 * z - y * y);
            var ny = this.speed * (-this.a * y - 4 * z - 4 * x - z * z);
            var nz = this.speed * (-this.a * z - 4 * x - 4 * y - x * x);

            x += this.h * nx;
            y += this.h * ny;
            z += this.h * nz;

            return { x: x, y: y, z: z };
        }

        randomize() {
            this.a = this.p.random(0.1, 3);
            this.x = this.p.random(-5, 5);
            this.y = this.p.random(-5, 5);
            this.z = this.p.random(-5, 5);
        }
    }

    class CenterText {
        constructor(p5Instance, easycamInstance, fontInstance, text) {
            this.p = p5Instance;
            this.easycam = easycamInstance;
            this.font = fontInstance;
            this.text = text;
            this.centerX = 0.1;
            this.centerY = 0;
            this.centerZ = 0;
            this.angle = 0.5;
            this.radius = 1.2;
            this.speed = 0.02;
            this.charSpacingAngle = 2; // Spacing between characters in degrees

            // Color properties
            this.r = 255;
            this.g = 255;
            this.b = 255;
            this.opacity = 255;
        }


        update() {
            this.angle += this.speed;
        }

        display() {
            this.p.push();

            // Set text properties
            if (this.font) {
                this.p.textFont(this.font);
                this.p.textSize(0.3);
                this.p.textAlign(this.p.CENTER, this.p.CENTER);
                this.p.noStroke();
                this.p.fill(255, 255, 255, this.opacity);
            }

            // Draw text along circular path
            const chars = this.text.split('');
            const angleStep = this.p.PI / 12.5;

            chars.forEach((char, i) => {
                const charAngle = this.angle + i * angleStep;
                const x = this.centerX + this.radius * this.p.cos(charAngle);
                const y = this.centerY + this.radius * this.p.sin(charAngle);

                this.p.push();
                this.p.translate(x, y, this.centerZ);

                // Rotate each character to face outward
                const rotationAngle = charAngle + this.p.PI / 2;
                this.p.rotateZ(rotationAngle);

                // Additional rotation to keep text readable
                if (this.easycam) {
                    this.p.rotateX(-100);
                    this.p.rotateY(-160);
                }

                this.p.text(char, -0.5, -0.4);
                this.p.pop();
            });

            this.p.pop();
        }
    }
    return <ReactP5Wrapper sketch={sketch} />;
});

export default HalvorsenAttractorComponent;
